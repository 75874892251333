import { createPinia } from 'pinia';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { defragText } from './store/extras';
import './store/index.js';
import { useFileStore } from './store/index.js';

const app = createApp(App);

const pinia = createPinia();
app.use(pinia);

const fileStore = useFileStore();
app.config.globalProperties.$fileStore = fileStore;

app.config.globalProperties.$defragText = defragText;

app.use(router).mount('#app')
