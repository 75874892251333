
import fileTypes from '@/data/fileTypes.json';
import programmes from '@/data/programmes.json';
import stages from '@/data/stages.json';
import technologies from '@/data/tech.json';

let ipAdresses = [];

const computers = [];

function generateNetwork(depth, numComputers, comp) {
    if (depth === 0) {
        return [];
    }

    const previousLevel = generateNetwork(depth - 1, numComputers, comp);

    for (let i = 0; i < numComputers; i++) {
        const computer = {
            id: generateUniqueIp(),
            name: `${comp}_${depth}_${i}`,
            files: [],
            connectedDevices: [],
            depth: depth
        };

        const numConnections = Math.min(previousLevel.length, Math.floor(Math.random() * 3) + 1);
        let connectedIndexes = new Set();
        while (connectedIndexes.size < numConnections) {
            connectedIndexes.add(Math.floor(Math.random() * previousLevel.length));
        }

        connectedIndexes.forEach(index => {
            addIpAdress(computer, previousLevel[index]);
        });

        computers.push(computer);
    }

    addIpAdress(computers[0], computers[Math.floor(Math.random() * computers.length)]);

    return computers;
}



function checkAllConnections(stage) {
    if (stage.length === 0) {
        console.log("No computers in the network.");
        return [];
    }

    let startNode = stage[0];
    if (!startNode) {
        console.log("Starting computer not found.");
        return [];
    }

    let queue = [startNode];
    let visited = new Set();
    visited.add(startNode.id);
    let connectedComputers = [];

    while (queue.length > 0) {
        let current = queue.shift();
        connectedComputers.push(current);

        current.connectedDevices.forEach(deviceId => {
            if (!visited.has(deviceId)) {
                visited.add(deviceId);
                let nextNode = stage.find(computer => computer.id === deviceId);
                if (nextNode) {
                    queue.push(nextNode);
                }
            }
        });
    }

    let disconnected = stage.filter(computer => !visited.has(computer.id));
    disconnected.forEach(computer => {
        let randomConnectedComputer = connectedComputers[Math.floor(Math.random() * connectedComputers.length)];
        addIpAdress(computer, randomConnectedComputer);
    });

    return connectedComputers;
}


function addIpAdress(pc1, pc2) {
    if (pc1.id == pc2.id) {
        return;
    }
    if (pc1.connectedDevices.includes(pc2.id)) {
        return;
    }
    if (pc2.connectedDevices.includes(pc1.id)) {
        return;
    }
    pc1.connectedDevices.push(pc2.id);
    pc2.connectedDevices.push(pc1.id);
}

function generateUniqueIp() {
    let ip = generateIp();
    while (ipAdresses.includes(ip)) {
        ip = generateIp();
    }
    ipAdresses.push(ip);
    return ip;
}

function generateOctet(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function generateIp() {

    const octets = [
        generateOctet(1, 254),
        generateOctet(0, 254),
        generateOctet(0, 254),
        generateOctet(0, 254)
    ];

    return octets.join('.');
}


function distributeTechFragments(computers, techFragments) {
    shuffleArray(techFragments);
    const fragmentsPerComputer = Math.ceil(techFragments.length / computers.length);

    computers.forEach(computer => {
        computer.files = generateFiles(techFragments.splice(0, fragmentsPerComputer), computer.id);
    });
}

function generateUid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

function generateFiles(techFragments, source) {
    const numFiles = Math.floor(Math.random() * 2) + 2;

    const files = [];
    const fragmentsPerFile = Math.ceil(techFragments.length / numFiles);

    for (let i = 0; i < numFiles; i++) {
        const type = fileTypes[Math.floor(Math.random() * fileTypes.length)];
        const id = generateFilename()
        const name = `${id}.${type.id}`;
        const uid = new Date().getTime() + "-" + i + "-" + generateUid();
        const fileFragments = techFragments.splice(0, fragmentsPerFile);

        files.push({ type, name, id, uid, data: fileFragments, source });
    }
    return files;
}

function assignFilesToComputers(computers, files) {
    files.forEach(file => {
        if (file.index === 'r') {
            const randomComputerIndex = Math.floor(Math.random() * computers.length);
            file.uid = new Date().getTime() + "-" + generateUid();
            file.source = computers[randomComputerIndex].id;
            file.name += ".exe"
            file.type = { id: "exe" }
            computers[randomComputerIndex].files.push(file);
        } else {
            const specificComputerIndex = file.index;
            if (specificComputerIndex < computers.length) {
                file.uid = new Date().getTime() + "-" + generateUid();
                file.source = computers[specificComputerIndex].id;
                file.name += ".exe"
                file.type = { id: "exe" }
                computers[specificComputerIndex].files.push(file);
            }
        }
    });
}

function generateStage(stage) {
    const allFragments = [];

    stage.techs.forEach(techId => {
        const tech = technologies.find(t => t.id === techId);
        const fragments = techSplitter(tech, tech.split);
        allFragments.push(...fragments);
    });

    const network = generateNetwork(stage.network.depth, stage.network.computers, stage.company);
    distributeTechFragments(network, allFragments);

    const program = programmes.filter(p => stage.files.some(file => file.id === p.id))
        .map(p => {
            const file = stage.files.find(file => file.id === p.id);
            return { ...p, index: file.index };
        });



    assignFilesToComputers(network, program);
    console.log(network)
    return network;
}

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

function techSplitter(tech, numberOfFragments) {
    const fragments = [];
    for (let i = 1; i <= numberOfFragments; i++) {
        fragments.push({ id: tech.id, fragment: i, max: numberOfFragments, res: tech.resource });
    }
    return fragments;
}


export function generateStages() {
    let stage = generateStage(stages[0])
    checkAllConnections(stage)
    return stage
}


function generateFilename() {
    const prefixes = ['net_', 'sys_', 'user_', 'admin_', 'temp_', 'cache_', 'config_', 'backup_'];
    const cores = Array.from({ length: 5 }, () => Math.floor(Math.random() * 100000));

    const prefix = prefixes[Math.floor(Math.random() * prefixes.length)];
    const core = cores[Math.floor(Math.random() * cores.length)];

    return `${prefix}${core}`;
}
