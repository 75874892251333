<template>
    <div class="list">
        <Process v-for="process in processes" :key="process.id" :process="process" />
    </div>
</template>

<script>
import Process from './Process.vue';

export default {
    name: 'ProcessesList',
    components: {
        Process
    },
    computed: {
        processes() {
            return this.$fileStore.processes;
        }
    }
}
</script>

<style scoped>
.list {
    height: 50px;
    display: flex;
    overflow: hidden;
    flex-direction: row;
}
</style>
