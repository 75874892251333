<template>
  <nav>
    <router-link to="/">{{ $defragText("Network") }}</router-link>
    <router-link v-if="showData" to="/data">{{ $defragText("Data") }}</router-link>
    <router-link v-if="showStats" to="/stats">{{ $defragText("Stats") }}</router-link>
  </nav>
  <Processes />
  <router-view />
</template>

<script>
import Processes from '@/components/Processes/Processes.vue';

export default {
  mounted() {
    this.$fileStore.initiate();
  },
  components: {
    Processes,
  },
  computed: {
    showStats() {
      return this.$fileStore.hasUpgrade('stats');
    },
    showData() {
      return this.$fileStore.data.length > 0;
    },
  },
}
</script>

<style>
body {
  background-color: #0d0d0d;
  color: #33ff33;
  font-family: 'Courier New', Courier, monospace;
  height: 100vh;
  overflow-y: auto;
  border: none;
  margin: 0px;
  padding: 0px;
}

nav {
  display: flex;
  justify-content: space-around;
  background-color: #0d0d0d;
  padding: 10px;
  height: 30px;
  overflow-y: auto;

  a {
    color: #33ff33;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #ff33ff;
    }
  }
}
</style>
