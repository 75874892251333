<template>
    <UpgradesList />
    <DataList :data-list="data" />
</template>

<script>
import DataList from '@/components/Data/DataList.vue';
import UpgradesList from '@/components/Upgrades/Upgrades.vue';

export default {
    name: 'DataView',
    computed: {
        data() {
            return this.$fileStore.getSortedDataFragments;
        }
    },
    components: {
        DataList,
        UpgradesList
    },
}
</script>

<style lang="scss" scoped>
h2 {
    margin: 10px;
    text-transform: capitalize;
}
</style>
