export function importState() {
    const isDebug = process.env.NODE_ENV === 'development';

    return {
        stage: null,
        files: [],
        nfiles: [],
        defrag: 100,
        processes: [],
        actions: [],
        cpu: isDebug ? 15 : 10,
        features: [],
        data: [],
        upgrades: [],
        res: {},
        allowed: [],
        pro: [],
        sUpgrades: [],
        pfiles: [],
        connected: [],
        lastDevice: null,
        baseIp: null
    };
}