import { createRouter, createWebHistory } from 'vue-router'
import DataView from '../views/DataView.vue'
import NetworkView from '../views/NetworkView.vue'
import StatsView from '../views/StatsView.vue'

const routes = [
  {
    path: '/',
    name: 'network',
    component: NetworkView
  },
  {
    path: '/data',
    name: 'data',
    component: DataView
  },
  {
    path: '/stats',
    name: 'stats',
    component: StatsView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
