<template>
    <div class="hard-item">
        <span>{{ cpu }}</span>
    </div>
    <div class="hard-item">
        <span>{{ defrag }}</span>
    </div>

    <div class="hard-item">
        <span>{{ $defragText("Programmes") }} </span>
    </div>

    <div class="upgrade-item" v-for="pro in programmes" :key="pro.id">
        <div>{{ pro.name }}</div>
        <div>{{ pro.desc }}</div>
    </div>

    <div class="hard-item">
        <span>{{ $defragText("Upgrades") }} </span>
    </div>

    <div class="upgrade-item" v-for="upgrade in upgrades" :key="upgrade.id">
        <div>{{ upgrade.name }}</div>
        <div>{{ upgrade.description }}</div>
    </div>
</template>

<script>
export default {
    computed: {
        programmes() {
            return this.$fileStore.pro;
        },
        upgrades() {
            return this.$fileStore.sUpgrades;
        },
        cpu() {
            return this.$defragText(`CPU: ${this.$fileStore.cpu} MHz`);
        },
        defrag() {
            return this.$defragText(`Defragmentation Level: ${this.$fileStore.defrag}`);
        },
    },
}
</script>

<style lang="scss" scoped>
.hard-item {
    margin: 5px;
    padding: 10px;
    border: 1px solid #ccc;
    display: flex;
    justify-content: space-between;

    &:hover {
        background-color: #333;
    }
}

.upgrade-item {
    margin: 5px;
    margin-left: 15px;
    padding: 10px;
    border: 1px solid #ccc;

    &:nth-child(odd) {
        background-color: #001402;
    }

    &:hover {
        background-color: #333;
    }
}
</style>
