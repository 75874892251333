<template>
    <button @click="performAction" class="action-button">{{ ActionText }}</button>
</template>

<script>
export default {
    name: 'ActionItem',
    props: {
        action: Object
    },
    computed: {
        ActionText() {
            return this.$defragText(this.action.name);
        }
    },
    methods: {
        performAction() {
            this.$fileStore.performAction(this.action);
        }
    }
}
</script>

<style scoped>
.action-button {
    user-select: none;
    background-color: #222;
    color: #33ff33;
    border: 1px solid #33ff33;
    padding: 10px 20px;
    font-family: 'Courier New', monospace;
    cursor: pointer;
    margin: 5px;
}

.action-button:hover {
    background-color: #333;
}
</style>
