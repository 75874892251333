import { defineStore } from 'pinia';
import actions from './actions';
import getters from './getters';
import { importState } from './state';

export const useFileStore = defineStore({
    id: 'fileStore',
    state: importState,
    actions,
    getters
});
