<template>
    <div class="actions-container">
        <Action v-for="action in actions" :key="action.id" :action="action" />
    </div>
</template>

<script>
import Action from './Action.vue';

export default {
    name: 'ActionsList',
    components: {
        Action
    },
    computed: {
        actions() {
            return this.$fileStore.activeActions;
        }
    }
}
</script>

<style scoped>
.actions-container {
    height: 50px;
    display: flex;
    flex-direction: row;
    padding: 20px;
    background-color: #0d0d0d;
}
</style>
