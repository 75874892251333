<template>
    <h2>{{ defragText("Data") }}</h2>
    <div class="data-list">
        <DataItem v-for="item in dataList" :key="item.id + '-' + item.fragment" :data="item" />
    </div>
</template>

<script>
import { defragText } from '@/store/extras';
import DataItem from './DataItem.vue';

export default {
    name: 'DataList',
    components: {
        DataItem
    },
    methods: {
        defragText
    },
    props: {
        dataList: {
            type: Array,
            required: true
        }
    }
}
</script>

<style>
.data-list {
    display: flex;
    flex-direction: column;
}
</style>