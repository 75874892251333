import upgrades from '@/data/upgrades';
import { defragText } from "./extras";

export default {
    activeActions(state) {
        let actions = JSON.parse(JSON.stringify(state.actions));

        if (state.defrag === 0) {
            actions = actions.filter(action => action.id !== 'defrag');
        }

        let defragProcessCount = state.processes.filter(process => process.id === 'defrag').length;
        if (defragProcessCount > 0) {
            let futureDefrag = state.defrag - defragProcessCount * state.cpu;
            if (futureDefrag <= 0) {
                actions = actions.filter(action => action.id !== 'defrag');
            }
        }

        actions = actions.filter(action => {
            if (action.category === 'feature') {
                let count = state.features.filter(feature => feature.id === action.id).length;
                if (count > 0) {
                    return false;
                }
            }
            return true;
        });

        actions = actions.filter(action => {
            if (action.category === 'feature') {
                let count = state.processes.filter(process => process.id === action.id).length;
                if (count > 0) {
                    return false;
                }
            }
            return true;
        });

        actions = actions.filter(action => {
            if (action.req.id == "NoStandardAction") {
                return false
            }

            if (Object.keys(action.req).length === 0) {
                return true
            }

            let req = action.req;

            if (req.id === "features") {
                return state.hasFeature(req.value)
            }

            if (req.id === "upgrades") {
                return state.hasUpgrade(req.value)
            }


            if (state[req.id] <= req.value) {
                return true;
            }
            return false;
        });

        return actions;
    },
    getIp(store) {
        if (store.connected.id == store.baseIp) {
            return null
        }
        return store.connected.id
    },
    getUpgrades(store) {
        const processIds = new Set(
            store.processes
                .filter(process => process && process.file && process.file.id)
                .map(process => process.file.id)
        );

        let availableUpgrades = upgrades.filter(upgrade =>
            upgrade &&
            !store.upgrades.includes(upgrade.id) &&
            !processIds.has(upgrade.id)
        );

        availableUpgrades = availableUpgrades.filter(upgrade => {
            if (!upgrade || !upgrade.req) {
                return false;
            }

            if (Object.keys(upgrade.req).length === 0) {
                return true;
            }

            if (upgrade.req.id === "features") {
                return store.hasFeature(upgrade.req.value);
            }

            if (upgrade.req.id === "upgrades") {
                return store.hasUpgrade(upgrade.req.value);
            }

            return false;
        });

        return availableUpgrades;
    },

    getSortedFiles: (state) => {
        let filesCopy = [...state.files];

        if (state.hasUpgrade('advsorting')) {
            return filesCopy.sort((a, b) => {
                if (a.type.id === "exe" && b.type.id !== "exe") return -1;
                if (a.type.id !== "exe" && b.type.id === "exe") return 1;
                return 0;
            });
        }

        if (state.hasUpgrade('sorting')) {
            return filesCopy.sort((a, b) => {
                if (a.type.id < b.type.id) return -1;
                if (a.type.id > b.type.id) return 1;
                return 0;
            });
        }

        return filesCopy;
    },

    getSortedNFiles: (state) => {
        let filesCopy = [...state.connected.files];

        if (state.hasUpgrade('advsorting')) {
            return filesCopy.sort((a, b) => {
                if (a.type.id === "exe" && b.type.id !== "exe") return -1;
                if (a.type.id !== "exe" && b.type.id === "exe") return 1;
                return 0;
            });
        }

        if (state.hasUpgrade('sorting')) {
            return filesCopy.sort((a, b) => {
                if (a.type.id < b.type.id) return -1;
                if (a.type.id > b.type.id) return 1;
                return 0;
            });
        }

        return filesCopy;
    },

    getNetworkName: (store) => (name) => {
        if (name === store.baseIp && store.hasUpgrade('displayOwnIp')) {
            return defragText(name + ' (Home)');
        }

        let pc = store.stage.find(computer => computer.id === name)

        if (store.hasUpgrade('scanner') && pc.files.length <= 0) {
            return defragText(name + ' (Empty)');
        }
        return defragText(name);
    },

    getSortedDataFragments: (state) => {
        if (!state.hasUpgrade('sorting')) {
            return state.data;
        }

        let data = state.data

        if (state.hasUpgrade('advsorting')) {
            data = [...data].reduce((acc, file) => {
                let existing = acc.find(f => f.id === file.id);
                if (existing) {
                    existing.fragments.push(file.fragment);
                } else {
                    file.fragments = [file.fragment];
                    acc.push({ ...file });
                }
                return acc;
            }, []);
        }

        return [...data].sort((a, b) => {
            if (a.id < b.id) return -1;
            if (a.id > b.id) return 1;
            return a.fragment - b.fragment;
        });
    }
}