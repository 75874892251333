import { useFileStore } from '@/store/index';

export function defragText(text) {

    const symbols = "#$%&*+=-@!~^<>?|{}[]()あいうえおアイウエオ漢字测试漢字測試ПриветКакДела";
    const defrag = useFileStore().defrag;

    if (defrag === 0 || text == undefined) {
        return text;
    }

    let result = '';
    for (let i = 0; i < text.length; i++) {
        if (Math.random() < defrag / 100) {
            result += symbols[Math.floor(Math.random() * symbols.length)];
        } else {
            result += text[i];
        }
    }
    return result;

}
