<template>
    <div class="process-bar-container">
        <div :style="{ width: progressBarWidth }" class="progress-bar">
            <span class="progress-text">{{ ProcessText }}</span>
            <span class="close" @click="exitProcess">X</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProcessItem',
    props: {
        process: {
            type: Object,
            required: true
        }
    },
    methods: {
        exitProcess() {
            this.$fileStore.exitProcess(this.process);
        }
    },
    computed: {
        progressBarWidth() {
            return `${(this.process.value / this.process.max) * 100}%`;
        },
        ProcessText() {
            return this.$defragText(this.process.name);
        }
    }
}
</script>

<style scoped>
.process-bar-container {
    user-select: none;
    background-color: #202020;
    border: 1px solid #ddd;
    padding: 3px;
    margin: 10px 10px;
    position: relative;
    min-width: 200px;
    width: 200px;
    height: 30px;
    min-height: 30px;
}

.close {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    color: #b90000;
}

.progress-bar {
    background-color: #20c997;
    height: 100%;
    display: flex;
    align-items: center;
}

.progress-text {
    color: #fff;
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-family: 'Courier New', monospace;
}
</style>
