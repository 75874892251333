<template>
    <div v-if="unlocked" class="files-container">
        <div class="error">{{ message }}</div>
        <div>{{ ip }}</div>
        <FileItem @error="error" @action="action" v-for="file in files" :key="file.id" :file="file" />
    </div>
</template>

<script>
import FileItem from './File.vue';

export default {
    name: 'FilesList',
    components: {
        FileItem
    },
    props: {
        source: String
    },
    data() {
        return {
            message: ""
        }
    },
    methods: {
        error(message) {
            this.message = message;
            setTimeout(() => {
                this.message = "";
            }, 2000);
        },
        isAllowed(file) {
            return this.$fileStore.getAllowed(file);
        },
        action(file) {
            if (this.source == 'nfiles') {
                this.$fileStore.downloadFile(file);
            } else if (this.source == 'files') {
                if (this.isAllowed(file)) {
                    this.$fileStore.analyzeFile(file)
                } else {
                    this.error(this.$defragText(`Cannot analyze .${file.type.id} files!`));
                }
            }
        }
    },
    computed: {
        ip() {
            if (this.source == 'nfiles') {
                return this.$fileStore.getIp;
            }
            return this.$fileStore.baseIp;

        },
        showFiles() {
            if (this.source == 'files') {
                return true;
            }
            if (this.source == 'nfiles') {
                return this.ip != null;
            }
            return false;
        },
        unlocked() {
            return this.$fileStore.hasFeature("files");
        },
        files() {
            if (!this.showFiles) {
                return [];
            }
            if (this.source == 'files') {
                return this.$fileStore.getSortedFiles;
            }

            if (this.source == 'nfiles') {
                return this.$fileStore.getSortedNFiles;
            }

            return [];
        }
    }
}
</script>

<style scoped lang="scss">
.error {
    color: red;
    margin-bottom: 10px;
}

.files-container {
    display: flex;
    flex-direction: column;
    background-color: #0d0d0d;
    padding: 20px;
    height: 200px;
    overflow-y: auto;
}
</style>
