<!-- Upgrades.vue -->
<template>
    <div>
        <div class=resources>
            <div class="resource" v-for="(k, res) in resources" :key="res">
                <div>{{ res }}</div>
                <div>{{ k }}</div>
            </div>
        </div>
        <div v-if="upgrades.length > 0">
            <h2>{{ defragText("Upgrades") }}</h2>
            <div class="upgrades-list">
                <div v-for="upgrade in upgrades" :key="upgrade.id">
                    <Upgrade :upgrade="upgrade" @upgraded="handleUpgrade" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useFileStore } from '@/store';
import { defragText } from '@/store/extras';
import Upgrade from './Upgrade.vue';

export default {
    name: 'UpgradesList',
    components: {
        Upgrade
    },

    computed: {
        resources() {
            return useFileStore().res;
        },
        upgrades() {
            return useFileStore().getUpgrades;
        }
    },
    methods: {
        defragText,
        handleUpgrade(upgrade) {
            useFileStore().buyUpgrade(upgrade);
        },

    }
}
</script>

<style scoped lang="scss">
.resources {
    display: flex;
    flex-wrap: wrap;
    margin: 5px 0px;
    flex-direction: row;
}

.resource {
    margin: 5px;
    border: 1px solid #d6d6d6;
    width: 100px;
    padding: 2px;

    &:nth-child(odd) {
        background-color: #001402;
    }

    &:hover {
        background-color: #525151;
    }


}

.upgrades-list {
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
    flex-direction: row;
}
</style>