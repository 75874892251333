<template>
    <div class="data-item">
        <span>{{ DataText }}</span>
        <span>{{ fragment }}</span>
    </div>
</template>

<script>
import { defragText } from '@/store/extras';

export default {
    name: 'DataItem',
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        fragment() {
            if (this.data.fragments != null) {
                return defragText(`Fragments: ${this.data.fragments.length} of ${this.data.max}`)
            }
            return defragText(`Fragment: ${this.data.fragment} of ${this.data.max}`)
        },
        DataText() {
            return defragText(this.data.id);
        }
    },
}
</script>

<style scoped lang="scss">
.data-item {
    margin: 5px;
    padding: 10px;
    border: 1px solid #ccc;
    display: flex;
    justify-content: space-between;

    &:nth-child(odd) {
        background-color: #0b002b;
    }

    &:hover {
        background-color: #333;
    }

}
</style>