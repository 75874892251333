<template>
  <div class="home">
    <Actions />

    <div class="flex">
      <Files :source="'files'" />
      <Files :source="'nfiles'" />
    </div>
    <Networks />
  </div>
</template>

<script>
import Actions from '@/components/Action/Actions.vue';
import Files from '@/components/Files/Files.vue';
import Networks from '@/components/Networks/Networks.vue';

export default {
  name: 'HomeView',
  components: {
    Actions,
    Files,
    Networks
  }
}
</script>

<style scoped>
.home {
  background-color: #0d0d0d;
  color: #33ff33;
  font-family: 'Courier New', Courier, monospace;
  padding: 20px;
  height: calc(100vh - 40px);
  overflow-y: auto;
  border: none;
}

.home::-webkit-scrollbar {
  width: 5px;
}

.home::-webkit-scrollbar-track {
  background: #0d0d0d;
}

.home::-webkit-scrollbar-thumb {
  background: #555;
}

.home::-webkit-scrollbar-thumb:hover {
  background: #777;
}

.flex {
  display: flex;
  justify-content: space-between;
}
</style>