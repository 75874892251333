<template>
    <div v-if="unlocked" class="networks-container">
        <div v-if="hasBreadcrumps" class="ip-adress">
            <span @click="goHome" class="home-breadcrumb">{{ $defragText("~/home") }}</span>
            <span @click="goLast" class="home-breadcrumb" v-if="lastDevice">/{{ lastDevice }}</span>
        </div>
        <Network v-for="network in networks" :key="'network-' + network" :network="network" />
    </div>
</template>

<script>
import Network from './Network.vue';

export default {
    name: 'NetworksList',
    components: {
        Network
    },
    methods: {
        goHome() {
            this.$fileStore.goHome();
        },
        goLast() {
            this.$fileStore.goLast();
        }
    },
    computed: {
        hasBreadcrumps() {
            return this.$fileStore.hasUpgrade('breadcrumbs');
        },
        lastDevice() {
            return this.$defragText(this.$fileStore.lastDevice);
        },
        networks() {
            return this.$fileStore.connected.connectedDevices;
        },
        unlocked() {
            return this.$fileStore.hasFeature("network");
        }
    }
};
</script>

<style>
.ip-adress {
    margin: 10px;
}

.home-breadcrumb {
    user-select: none;
    color: #33ff33;
    cursor: pointer;
    padding: 5px 0;
    font-family: 'Courier New', monospace;
}

.home-breadcrumb:hover {
    text-decoration: underline;
}

.networks-container {
    display: flex;
    flex-direction: column;
    background-color: black;
    color: lime;
    font-family: 'Courier New', Courier, monospace;
    padding: 20px;
}
</style>
