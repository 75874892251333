<template>
    <div @click="connect" class="network">
        <h2>{{ networkName }}</h2>
    </div>
</template>

<script>
export default {
    name: 'NetworkItem',
    props: {
        network: {
            type: String,
            required: true
        }
    },
    computed: {
        networkName() {
            return this.$fileStore.getNetworkName(this.network);
        }
    },
    methods: {
        connect() {
            this.$fileStore.connectToDevice(this.network);
        }
    }
};
</script>

<style lang="scss" scoped>
.network {
    user-select: none;
    display: flex;
    flex-direction: column;
    border: 1px solid lime;
    margin-bottom: 10px;
    padding: 5px;
    width: 300px;
    cursor: pointer;

    &:hover {
        background-color: #333;
    }
}

.network h2 {
    font-size: 1.2em;
}
</style>
