//pinia action
import { generateStages } from './generator';

export default {
    initiate() {

        setInterval(() => {
            this.update()
        }, 100)

        import('@/data/actions.json').then(module => {
            this.actions = module.default;
        });

        if (!this.loadGame()) {
            this.createStage();
        }

    },
    createStage() {
        this.stage = generateStages();
        this.connected = this.stage[0];
        this.files = this.connected.files;
        this.baseIp = this.connected.id;
    },
    goHome() {
        this.connectToDevice(this.baseIp);
    },
    goLast() {
        if (this.lastDevice) {
            this.connectToDevice(this.lastDevice);
        }
    },
    connectToDevice(id) {
        if (id == this.baseIp) {
            this.lastDevice = null;
        } else {
            this.lastDevice = JSON.parse(JSON.stringify(this.connected.id));
        }

        let computer = this.stage.find(computer => computer.id === id);
        this.connected = computer;
    },
    saveGame() {
        localStorage.setItem('game', JSON.stringify(this.$state))
    },
    loadGame() {
        let game = localStorage.getItem('game')
        if (game) {
            this.$state = JSON.parse(game)
            return true
        }
        return false
    },
    getAllowed(file) {
        if (file.action) return true;
        return this.allowed.includes(file.type.id)
    },
    hasUpgrade(upgrade) {
        return this.upgrades.includes(upgrade)
    },
    hasFeature(feature) {
        return this.features.find(x => x.id == feature) !== undefined
    },
    canBuyUpgrade(upgrade) {
        if (!upgrade) return false
        let res = upgrade.cost;
        for (let r of res) {
            if (!this.res[r.id] || this.res[r.id] < r.value) {
                return false
            }
        }
        return true
    },
    buyUpgrade(upgrade) {
        if (!this.hasUpgrade(upgrade) && this.canBuyUpgrade(upgrade)) {
            let action = this.actions.find(action => action.id === "upgrade")
            this.performAction(action, upgrade)

            let res = upgrade.cost;
            for (let r of res) {
                this.res[r.id] -= r.value
            }
        }
    },
    performAction(action, file = null) {
        if (file && (
            (action.category === "download" && this.isFileDownloaded(file.uid)) ||
            (action.category === "analyze" && this.isFileAnalyzed(file.uid))
        )) {
            console.warn(`File with UID ${file.uid} is already processed for action ${action.category}.`);

            if (action.category === "analyze" && !!file.action) {
                this.files = this.files.filter(f => f !== file);
            }
            return;
        }


        let clone = Object.assign({}, action)
        clone.max = 100;
        clone.value = 0;

        if (file && action.category === "download") {
            clone.file = Object.assign({}, file)
            clone.name = "Downloading: " + file.name

            let computer = this.stage.find(computer => computer.id == clone.file.source);
            if (computer) {
                computer.files = computer.files.filter(file => file.uid !== clone.file.uid);
            }
        }

        if (file && action.category === "analyze") {
            clone.file = Object.assign({}, file)
            clone.name = "Analyzing: " + file.name
            this.files = this.files.filter(f => f !== file);
        }

        if (file && action.category === "upgrade") {
            clone.file = Object.assign({}, file)
            clone.max = file.power;
            clone.name = "Upgrading: " + file.name
        }

        clone.uid = new Date().getTime() + "-" + Math.random().toString(36).substr(2, 9);
        this.processes.push(clone)
    },

    downloadFile(file) {
        let action = this.actions.find(action => action.id === "download")
        this.performAction(action, file)
    },

    analyzeFile(file) {
        let action = this.actions.find(action => action.id === "analyze")
        this.performAction(action, file)
    },

    update() {
        let proc = this.processes[0]
        if (!proc) return
        proc.value += this.cpu
        if (proc.value >= proc.max) {
            this.completeAction(proc)
        }
    },
    completeAction(action) {
        this.defrag += 0.1;
        if (action.category == "special") {
            if (action.id == "defrag") {
                this.defrag -= this.cpu;
                if (this.defrag < 0) {
                    this.defrag = 0;
                }
            }
        } else if (action.category === "feature") {
            if (!this.features.includes(action)) {
                this.features.push(action);
            }
        } else if (action.category == "download") {
            if (!this.isFileDownloaded(action.file.uid)) {
                this.files.push(action.file);
            } else {
                console.warn(`File with UID ${action.file.uid} is already processed for action ${action.category}.`);
            }
        } else if (action.category == "analyze") {
            if (!this.isFileAnalyzed(action.file.uid)) {
                if (action.file.data) {
                    for (let d of action.file.data) {
                        for (let res of d.res) {
                            if (!this.res[res.id]) {
                                this.res[res.id] = 0
                            }
                            this.res[res.id] += res.value
                        }
                    }
                    this.data.push(...action.file.data);
                    this.pfiles.push(action.file.uid)
                }
                else if (action.file.action) {
                    this.allowed.push(action.file.action.allows)
                    this.pro.push(action.file)
                }
            } else {
                console.warn(`File with UID ${action.file.uid} is already processed for action ${action.category}.`);
            }
        } else if (action.category == "upgrade") {
            this.upgrades.push(action.file.id);
            this.sUpgrades.push(action.file)
        }
        this.processes.shift()
        this.saveGame()
    },
    exitProcess(action) {
        this.processes = this.processes.filter(process => process.uid !== action.uid);
    },
    isFileDownloaded(uid) {
        return this.files.some(file => file.uid === uid);
    },

    isFileAnalyzed(uid) {
        return this.pfiles.some(data => data.file && data.file.uid === uid) ||
            this.pro.some(pro => pro.uid === uid);
    }
}