<!-- Upgrade.vue -->
<template>
    <div class="upgrade-item" :class="{ 'upgrade-unavailable': !canUpgrade }">
        <h3>{{ upgradeText }}</h3>
        <p>{{ upgradeDescription }}</p>
        <p>Cost:</p>
        <ul>
            <li v-for="cost in upgrade.cost" :key="cost.id" :style="{ color: resourceColor(cost) }">
                {{ cost.id }}: {{ cost.value }}
            </li>
        </ul>
        <p>CPU Power Required: {{ upgrade.power }}</p>
        <button @click="applyUpgrade" :disabled="!canUpgrade">{{ defragText("Upgrade") }}</button>
    </div>
</template>

<script>
import { useFileStore } from '@/store';
import { defragText } from '@/store/extras';

export default {
    name: 'UpgradeItem',
    props: {
        upgrade: {
            type: Object,
            required: true
        }
    },
    computed: {
        canUpgrade() {
            return useFileStore().canBuyUpgrade(this.upgrade);
        },
        upgradeText() {
            return defragText(this.upgrade.name);
        },
        upgradeDescription() {
            return defragText(this.upgrade.description);
        }
    },
    methods: {
        defragText,
        resourceColor(cost) {
            const store = useFileStore();
            const available = store.res[cost.id] || 0;
            return available >= cost.value ? 'green' : 'red';
        },
        applyUpgrade() {
            this.$emit('upgraded', this.upgrade);
        }
    }
}
</script>


<style scoped>
.upgrade-item {
    border: 1px solid #00FF00;
    padding: 10px;
    margin-bottom: 10px;
    width: 300px;
    margin: 10px;
    height: auto;
    background-color: #000;
    color: #00FF00;
    font-family: 'Courier New', Courier, monospace;
}

.upgrade-unavailable {
    color: #777;
    border-color: #777;
}

button {
    background-color: #00FF00;
    border: 1px solid #00FF00;
    color: #000;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    font-family: 'Courier New', Courier, monospace;
}

button:disabled {
    background-color: #555;
    border: 1px solid #777;
    cursor: not-allowed;
    color: #777;
}
</style>