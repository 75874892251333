<!-- File.vue -->
<template>
    <div @click="action" class="file">
        {{ FileText }}
    </div>
</template>

<script>
import { defragText } from '@/store/extras';

export default {
    name: 'FileItem',
    props: {
        file: Object
    },
    computed: {
        FileText() {
            return defragText(this.file.name);
        },

    },
    methods: {
        action() {

            this.$emit('action', this.file);

        }
    }

}
</script>

<style scoped lang="scss">
.file {
    user-select: none;
    width: 200px;
    background-color: #333;
    color: #33ff33;
    padding: 10px;
    margin: 5px 0;
    font-family: 'Courier New', monospace;
    cursor: pointer;

    &:hover {
        background-color: #1a1a1a;
    }
}
</style>